module.exports = {
    shippingCourierTypes: ['jne', 'siCepat', 'sap', 'iDexpress', 'jNt', 'ninja'],
    shippingCourierItems: {
      "jne": {
        val: "jne", label: "JNE", label2: "JNE", imageSrc: `/img/shippingCouriers/JNE.png`
      },
      "siCepat": {
        val: "siCepat", label: "SiCepat", label2: "SiCepat", imageSrc: `/img/shippingCouriers/Sicepat.svg`
      },
      "sap": {
        val: "sap", label: "SAP", label2: "SAP", imageSrc: `/img/shippingCouriers/SAP.svg`
      },
      "iDexpress": {
        val: "iDexpress", label: "iDexpress", label2: "iDexpress", imageSrc: `/img/shippingCouriers/iDexpress.png`
      },
      "jNt": {
        val: "jNt", label: "J&T", label2: "J&T",  imageSrc: `/img/shippingCouriers/JT.png`
      },    
      "ninja": {
        val: "ninja", label: "Ninja Xpress", label2: "Ninja Xpress", imageSrc: `/img/shippingCouriers/ninja.png`
      },
      "lion": {
        val: "lion", label: "Lion Parcel", label2: "Lion Parcel", imageSrc: `/img/shippingCouriers/lion.svg`
      }
    }
  }