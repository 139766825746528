<script>
import SelectCoupon from '../../SelectCoupon.vue';
import moment from 'moment'
import axios from 'axios'
import config from '@/config.js'
export default {
  props: [   
    "data",
    "payload",
    "discountLists",
    "cartSummary",
    "totalQuantity",
    "totalPrice",
    "price"     
  ],
  components: {
    SelectCoupon,
  },
  data() {
    return {
      modal: false ,
      searchCoupon:'',
      coupons:[],
      applied_discount:false,     
      moment,
      isSearchLoading: false,
    }
  },
  mounted(){
    this.coupons=this.discountLists
  },
  watch:{
   
  },
  methods:{   
    async applyCoupon(){
      this.isSearchLoading = true;
      this.applied_discount=true
      const search=this.searchCoupon.length > 3 ? this.searchCoupon :'';
      const response = await axios.get(config.api +"product/discountList/"+this.data._id+"?offset="+new Date().getTimezoneOffset()+"&user_id="+this.data.user_id+"&search="+search+"&includeHiden=true");      

      if (response && response.data.success) {
        // Step 1: Get fields with non-zero counts
        const couponExist = Object.keys(response.data.count).filter(key => response.data.count[key] > 0);

        // Step 2: Collect matching data as an array
        const couponData = couponExist
          .flatMap(field => (Array.isArray(response.data.data[field]) ? response.data.data[field] : []));

        const discountLists = couponData.filter(d=>d.discount_value.applies_to.type=='spcific_products' && d.discount_value.applies_to.products.length > 0 ? d.discount_value.applies_to.products.indexOf(this.data._id) > -1 : true);

       const discount= discountLists.find(x=>x.title==search)
        
        if(discount){
          this.updateDiscountLists(discountLists,false)  
          // const isApply=this.checkdisabled(discount)  
          // if(isApply){
          //   this.$emit('selectDiscount',discount)
          // }       
          this.$emit('selectDiscount',discount)    
          this.coupons=this.discountLists
          this.searchCoupon=''
        } else {
          this.$vs.notification({
            color: "danger",
            position: "top-right",
            text: this.$t('coupon.errorMessage.couponNotFoundOrMeetRequirements'),//"Please fix fields error before proceeding to next step",
            duration: 2000,
          });
        }
       
      }else{
          this.coupons=[]
        }
      this.isSearchLoading = false;
    },
    checkdisabled(discount) {            
      let discount_amount=0;    
   
      let price =  this.data.price;  
      let totalQuantity=this.totalQuantity;

      if(this.data.cartSummary && this.data.cartSummary.length > 0){
          totalQuantity=0;
          price = 0;
          this.data.cartSummary.map(async (e) => {      
            totalQuantity = totalQuantity + e.qty;     
            let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
              if (e.whosaleData) {
                e.whosaleData.map((one) => {
                  if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
                    subPrice = one.price * parseInt(e.qty);
                  }               
                });
              }             
              price = price + subPrice;     
           
        });
      }      

      if (discount.product_type !== this.data.productType) {
        return false;
      }

     if(discount.minimum_requirements.type=='minimum_purchase'){         
        if(Number(discount.minimum_requirements.value)>this.totalPrice){
          return discount_amount > 0 
        }        
      }else if(discount.minimum_requirements.type=='minimum_quantity'){       
        if(Number(discount.minimum_requirements.value)>totalQuantity){
          return discount_amount > 0 
        }        
      }       

      if (discount.payment_type && discount.payment_type !== 'both') {
        const isCOD = this.payload.payment === 'COD';
        if (
          (discount.payment_type === 'cod' && !isCOD) ||
          (discount.payment_type === 'non_cod' && isCOD)
        ) {
          return false;
        }
      }

      if(discount.discount_type=="moneyOffProduct"){


          if(discount.discount_value.type=="fixed"){                   
            discount_amount=(Number(discount.discount_value.value));           
          }else{
            discount_amount =(price *Number(discount.discount_value.value)) / 100;                                        
                           
          }       
          
        }else if(discount.discount_type=="moneyOffOrder"){
          if(discount.discount_value.type=="fixed"){                   
            discount_amount=Number(discount.discount_value.value);         
          }else{
            discount_amount =(this.totalPrice *Number(discount.discount_value.value)) / 100;                             
                    
          }  
        }else if(discount.discount_type=="moneyOffShipping"){
          if (this.payload.deliveryPrice) {
            if (discount.discount_value.applies_to.shipping_type=='all' || discount.discount_value.applies_to.shipping_type==this.payload.deliveryCourier) {
              if (discount.discount_value.type=="fixed"){                   
                discount_amount=Number(discount.discount_value.value);         
              } else{
                discount_amount =(this.payload.deliveryPrice *Number(discount.discount_value.value)) / 100;    
              }

              return discount_amount > 0    
            }
          }

          return true;
        }     
    
        return discount_amount > 0        
    },
    clearDiscountCode(code){
      this.searchCoupon=''
      this.$emit('clearDiscountCode', code)
    },
    selectDiscount(discount){
      this.$emit('selectDiscount',discount)
    },
    updateDiscountLists(discounts,replace){
      this.$emit('updateDiscountLists',discounts,replace)
    },

  },
  computed: {
    totalDiscountAmount() {
      let totalAmount = this.payload.automaticDiscount.code ? this.payload.automaticDiscount.amount : 0;
      for (const discount of this.payload.discounts) {
        totalAmount += discount.amount;
      }

      return totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    totalCountAppliedCoupons() {
      let count = this.payload.automaticDiscount.code ? 1 : 0;
      count += this.payload.discounts.length;
      
      return count;
    }
  }
}
</script>

<template>
  <div class="coupon">
    <div class="flex items-center gap-16">
      <img src="@/assets/images/illustrations/icon-illustration-coupon.svg" alt="O">
      <div class="w-100">
        <p class="fs-14 fw-700 color-primary">{{$t('coupon.HaveCouponCode')}}</p>
        <div class="flex items-start gap-16" style="flex-wrap: wrap; row-gap: 8px;">
          <div style="flex: 1 0 160px;">
            <vs-input v-model="searchCoupon" :class="{'is-invalid':searchCoupon!='' && this.coupons.length==0 && applied_discount}" class="vs-form-control w-100" :placeholder="$t('coupon.entercouponhere')"></vs-input>
            <span  v-if="searchCoupon!='' && this.coupons.length==0 && applied_discount && !isSearchLoading" class="invalid-feedback-custom">{{$t('coupon.Couponnotfound')}}</span>
          </div>
          <button @click="applyCoupon" :disabled="searchCoupon.length < 4" class="pp-button-green--fill">{{$t('coupon.Use')}}</button>
        </div>
      </div>
    </div>

    <div class="fff-coupon-applied-container mt-3" v-if="totalCountAppliedCoupons" @click="modal = true">
      <div class="fff-coupon-applied-icon">
        <img src="@/assets/icons/coupon/coupon-applied.svg" />
      </div>
      <div class="fff-coupon-applied-description">
        <div class="fff-coupon-applied-title">({{ totalCountAppliedCoupons }}) {{ $t("coupon.appliedCoupon.title") }}</div>
        <div class="fff-coupon-applied-subtitle">{{ $t("coupon.appliedCoupon.subtitle") }} <span class="fff-total-discounted">Rp. {{ totalDiscountAmount }} 🌟</span></div>
      </div>
      <div class="fff-coupon-applied-action-icon">
        <img src="@/assets/icons/general/chevron-right.svg" />
      </div>
    </div>
    <button v-if="!totalCountAppliedCoupons" @click="modal = true" class="pp-button-green--fill pp-button-green--smooth w-100">{{$t('coupon.ViewMoreCoupons')}}</button>
    <SelectCoupon v-model="modal" theme="classic" :data="data" :payload="payload" @updateDiscountLists="updateDiscountLists"  @selectDiscount="selectDiscount" @clearDiscountCode="clearDiscountCode" :discountLists="discountLists" :totalQuantity="totalQuantity" :cartSummary="cartSummary" :totalPrice="totalPrice" :price="price" />    
  </div>
</template>
 

<style lang="scss" scoped>
.bordered-selected-coupon {
  background: #FEEBEB80;
  padding: 8px 4px;
  border-radius: 8px;
}

button {
  height: 40px;
  color: #FFF;
  border: none;
  border-radius: 8px;
  border-color: #14AD4D !important;
}

.color-primary {
    color: #203551 !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.gap-16 {
  gap: 16px;
}

.color-mute {
  color: #6C7E95
}

[role=button] {
  cursor: pointer;
}
.coupon {
  padding: 16px;
  background-color: #F6F8FC;
  border: 1px solid #E0E9F4;
  border-radius: 8px;

  p {
    margin-bottom: 6px;
  }

  .is-invalid {
    &::v-deep {
      input {
        border-color: #E12121 !important;
      }
    }
  }

  .invalid-feedback-custom {
    color: #E12121;
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    margin-top: 6px;
    display: inline-block;
  }

  .pp-button-green {
    &--fill {
      flex-shrink: 0;
      width: 116px;
      font-size: 14px;
      font-weight: 500;
      background: #14AD4D;
      @media screen and (max-width: 447px) {
        width: 100%;
      }
    }
    &--smooth {
      margin-top: 16px;
      font-size: 14px;
      font-weight: 700;
      color: #14AD4D;
      background: #EBFBF1;
      border: 1px solid #14AD4D; 
    }
  }

  &__applied {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    padding: 16px;
    background: #FFF;
    border: 1px solid #E0E9F4;
    border-radius: 8px;
  }

  &::v-deep {
    input {
      border: 1px solid #E0E9F4;
      box-shadow: 0px 1px 2px 0px #1018280D;
      font-size: 14px;
    }
    .vs-input__label {
      font-weight: 400;
      left: 15px;
    }
  }

  .fff-coupon-applied-container {
    height: 58px;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 9px 12px;
    border-radius: 8px;
    border: 1px solid #14AD4D;
    background-color: #EBFBF1;
    cursor: pointer;

    .fff-coupon-applied-description {
      display: flex;
      flex-direction: column;

      .fff-coupon-applied-title {
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        color: #203551;
      }

      .fff-coupon-applied-subtitle {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #203551;

        .fff-total-discounted {
          font-size: 12px;
          font-weight: 700;
          text-align: left;
          color: #203551;
        }
      }
    }

    .fff-coupon-applied-action-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      padding: 10px;
    }
  }
}
</style>