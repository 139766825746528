<script>
import {debounce} from 'lodash'
import moment from 'moment'
import axios from 'axios'
import config from '@/config.js'
export default {
  props: [   
    "value",
    "data",       
    "payload",
    "theme",
    "discountLists",
    "cartSummary",
    "totalQuantity",
    "totalPrice",
    "price"      
  ], 
  data() {
    return {
      modal: false,   
      searchCoupon:'',
      couponPaging: {
        size: 5,
        page: 1      
      },
      couponTotal:0,
      couponCount:0,
      coupons:[],
      moment
    }
  },
  mounted(){
    const self=this;
    this.handleAutofill = debounce(async function () {
      const search=self.searchCoupon.length > 3 ? self.searchCoupon :'';    
    
      const response = await axios.get(config.api +"product/discountList/"+this.data._id+"?offset="+new Date().getTimezoneOffset()+"&user_id="+this.data.user_id+"&search="+search+"&size="+this.couponPaging.size+"&page="+this.couponPaging.page);      
      if (response.data.success && response.data.data && response.data.data.length > 0) {
        const discountLists = response.data.data.filter(d=>d.discount_value.applies_to.type=='spcific_products' && d.discount_value.applies_to.products.length > 0 ? d.discount_value.applies_to.products.indexOf(this.data._id) > -1 : true);      
        this.coupons=discountLists;          
        this.couponTotal=response.data.count
        this.couponCount=response.data.count  > this.couponPaging.size ? Math.ceil(response.data.count/this.couponPaging.size) : 1  
        this.updateDiscountLists(discountLists,false) 
      }else{
        this.coupons=[];
        this.couponTotal=0
        this.couponCount=0
      }     
    
    }, 500);
   
    //this.coupons=this.discountLists;
  },
  computed: {
    assets() {
      return {
        icon: {
          tuktuk: require('@/assets/icons/discount-circle-red.svg'),
          classic: require('@/assets/icons/discount-circle.svg'),
        },
        item: {
          tuktuk: require('@/assets/images/illustrations/icon-illustration-coupon-item-red.svg'),
          classic: require('@/assets/images/illustrations/icon-illustration-coupon-item.svg'),
        },
        empty: {
          tuktuk: require('@/assets/images/empty-state/coupons-empty-red.svg'),
          classic: require('@/assets/images/empty-state/coupons-empty-green.svg'),
        }
      }
    },
    width() {
      return this.theme === 'classic' ? '578px' : '530px'
    }
  },
  methods:{  
    checkdisabled(discount) {       
      let totalQuantity=this.totalQuantity;
      let price =  this.price;  
      if(this.cartSummary && this.cartSummary.length > 0){
          totalQuantity=0;
          price = 0;
          this.cartSummary.map(async (e) => {      
            totalQuantity = totalQuantity + e.qty;     
            let subPrice = (e.price ? e.price : e.priceOriginal) * e.qty;
              if (e.whosaleData) {
                e.whosaleData.map((one) => {
                  if (one.count && parseInt(one.count) <= parseInt(e.qty)) {
                    subPrice = one.price * parseInt(e.qty);
                  }               
                });
              }             
              price = price + subPrice;     
           
        });
      }      
        
      let discount_amount=0;        
      if(discount.minimum_requirements.type=='minimum_purchase'){         
          if(Number(discount.minimum_requirements.value)>this.totalPrice){
            return discount_amount > 0 
          }        
        }else if(discount.minimum_requirements.type=='minimum_quantity'){       
          if(Number(discount.minimum_requirements.value)>totalQuantity){
            return discount_amount > 0 
          }        
        } 
       
        if(discount.payment_type=='cod' || this.payload.payment=='COD'){           
          if(this.payload.payment!='COD' && discount.payment_type=='cod'){        
            return discount_amount > 0 
          }else if(this.payload.payment=='COD' && discount.payment_type=='non_cod'){        
            return discount_amount > 0 
          }        
        }

        if(discount.discount_type=="moneyOffProduct"){
            if(discount.discount_value.type=="fixed"){                   
              discount_amount=(Number(discount.discount_value.value));           
            }else{
              discount_amount =(price *Number(discount.discount_value.value)) / 100;                                        
                            
            }       
            
          }else if(discount.discount_type=="moneyOffOrder"){
            if(discount.discount_value.type=="fixed"){                   
              discount_amount=Number(discount.discount_value.value);         
            }else{
              discount_amount =(this.totalPrice *Number(discount.discount_value.value)) / 100;                             
                      
            }  
          }          
       
        return discount_amount > 0        
    },
    clearDiscountCode(){
      this.$emit('clearDiscountCode')
      this.modal=false
    },   
    updateDiscountLists(discounts,replace){     
      this.$emit('updateDiscountLists',discounts,replace)
    },
    applyDiscount(discount){   
      const isApply=this.checkdisabled(discount)   
      if(isApply){       
        this.$emit('selectDiscount',discount)
        this.modal=false
      }
    }
  },
  watch: {
    'couponPaging.page': {
        async handler() {              
            this.handleAutofill()              
        }
    },
    value(open) {
      if(open){
        this.coupons=[...this.discountLists]
        this.searchCoupon=''
      }
      this.modal = open
    },
    modal(open) {
      if(open){
        this.couponPaging.page=1
        this.handleAutofill('')
      }
      if (!open) this.$emit('input', false)
    },
  'searchCoupon': {
        async handler(val) {
            if (val.length > 3) {
              this.couponPaging.page=1
              this.handleAutofill(val)
            }else{
              this.handleAutofill('')
            }
        }
    },    
  }
}
</script>

<template>
  <vs-dialog v-model="modal" :data-theme="theme" class="pp-modal-vuesax variant-modal pp-modal-choose-variant--mobile" :width="width">
    <div class="pp-modal-vuesax__header">
      <img :src="assets.icon[theme || 'classic']" alt="O">
      <h3 class="m-0">{{$t('coupon.CouponList')}}</h3>
    </div>
    <hr class="m-0" />    
    <div class="pp-modal-vuesax__main">
      <input v-model="searchCoupon" class='search-white border w-100' :placeholder="$t('coupon.Lookforcoupons')" />
      <template v-if="coupons.length>0">
        <div class="coupon__item" :key="`coupon-${index}`" v-for="discount,index in coupons">
          <div class="flex items-center">
            <img :src="assets.item[theme || 'classic']" alt="O">
            <div class="coupon__item__divider"></div>
          </div>
          <div class="flex-grow-1">
            <p class="coupon__item__name">{{ discount.title }}</p>          
            <template v-if="discount.discount_value.type === 'fixed'">
              <p class="coupon__item__code">{{$t('coupon.Discount')}} {{ 'RP' +Number(discount.discount_value.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</p>
            </template>
            <template v-else>
              <p class="coupon__item__code">{{$t('coupon.Discount')}} {{ discount.discount_value.value ? discount.discount_value.value + "% " : "" }}</p>
            </template>
            <template v-if="discount.minimum_requirements.type == 'minimum_purchase'">
              <p  class="coupon__item__description">
                {{ discount.minimum_requirements.value
                  ? `Minimum purchase of RP ${Number(discount.minimum_requirements.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  : $t('coupon.NoMinPurchaseRequirement')
                }}
              </p>
            </template>
            <template v-else-if="discount.minimum_requirements.type == 'minimum_quantity'">
              <p class="coupon__item__description">
                {{ discount.minimum_requirements.value
                  ? `Minimum purchase of ${discount.minimum_requirements.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} items`
                  : $t('coupon.NoMinPurchaseRequirement')
                }}
              </p>
            </template>
            <template v-else>
              <p class="coupon__item__description">{{ $t('coupon.NoMinPurchaseRequirement') }}</p>
            </template>    
           
            <p class="coupon__item__description" v-if="discount.end_date"> 
              
              {{discount.end_date  ? moment(discount.full_end_date).diff(moment(discount.full_start_date), 'days')  > 0 ? "Until "+moment(discount.full_end_date).diff(moment(discount.full_start_date), 'days')+" days" : 'Until today here' : '' }}</p>
          

          </div>
          <button :class="{'disabled':!checkdisabled(discount)}" :disabled="!checkdisabled(discount)" @click="applyDiscount(discount)"  v-if="payload.discount.code!=discount.title" class="pp-button--fill">{{$t('coupon.ClaimCoupon')}}</button>
          <button @click="clearDiscountCode" v-else class="pp-button--border">{{$t('coupon.Wipe')}}</button>
        </div>               
        <vs-pagination v-model="couponPaging.page" :length="couponCount" :total="couponTotal"  />
      </template>
      <div v-else class="flex flex-column items-center justify-center" style="padding: 80px">
        <img :src="assets.empty[theme || 'classic']" alt="Empty" style="margin-bottom: 8px;">
        <p class="fs-14 fw-500 color-mute m-0">{{$t('coupon.Couponnotfound')}}</p>
      </div>
    </div>
  </vs-dialog>
</template>

 

<style lang="scss" scoped>
[data-theme="tuktuk"] {
  h3 {
    font-family: TikTokDisplayMedium !important;
    font-weight: 600;
  }

  .coupon__item {
    background: #FEEBEB;
    border-radius: 16px;
    row-gap: 0 !important;

    @media screen and (max-width: 500px) {
      grid-template-columns: auto 1fr !important;
      grid-template-rows: auto auto;
      row-gap: 16px !important;
      padding: 16px !important;
    }

    &::before {
      content: '';
      position: absolute;
      top: -12px;
      left: 56px;
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 48px;
      background: #FFF;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      left: 56px;
      display: block;
      height: 24px;
      width: 24px;
      border-radius: 48px;
      background: #FFF;
    }

    &>div:first-child {
      grid-row: 1 / 3;
    }

    img {
      margin-right: 24px;
      height: 40px;
      width: 40px;
    }
    
    &__divider {
      position: absolute;
      top: 50%;
      left: 56px;
      transform: translateY(-50%);
      height: 140px;
      margin: 0 10px;
      border-left: 4px dashed #FFF;
    }

    &__name {
      font-family: TikTokDisplayBold;
      margin-bottom: 4px;
      color: #203551;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
      }
    }
    &__code {
      margin-bottom: 4px;
      color: #F53535;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 18px;
      }
    }
    &__description {
      margin-bottom: 0px;
      color: #15171C;
      opacity: 0.4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        font-size: 12px;
        margin-bottom: 0px;
        line-height: 18px;
      }
    }
  }
  .pp-button {
    &--fill {
      background: #F75656;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 2 / 3;
      }
    }
    &--border {
      color: #F75656;
      background: #fff;
      border: 1px solid #F75656 !important;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 2 / 3;
      }
    }
  }
  &::v-deep {
    .vs-pagination {
      gap: 8px !important;

      &-content {
        gap: 8px !important;
      }

      &__button {
        color: #EB435B;
        background-color: #FFF;
        border-radius: 40px;
        border: 1px solid #E0E9F4;
        border-radius: 40px !important;
      }

      &__active {
        color: #FFF;
        background-color: #EB435B !important;
        box-shadow: 0px 10px 20px 0px #00136A40;
        border-radius: 40px !important;
      }

      &__arrow {
        background-color: #FFF;
        border: 1px solid #EB435B !important;
        border-radius: 40px !important;
        
        .vs-icon-arrow::before,
        .vs-icon-arrow::after {
          background-color: #EB435B !important;
        }

        &:disabled {
          opacity: 1 !important;
          border: 1px solid #E0E9F4 !important;
          
          .vs-icon-arrow::before,
          .vs-icon-arrow::after {
            background-color: #203551 !important;
          }
        }
      }
    }
  }
}
[data-theme="classic"] {
  .coupon__item {
    background: #FFF;
    border-radius: 8px;
    border: 1px solid #E0E9F4;

    @media screen and (max-width: 500px) {
      gap: 8px !important;
      grid-template-columns: auto 1fr !important;
      grid-template-rows: auto auto;
      padding: 12px !important;
    }

    img {
      height: 64px;
      width: 64px;
    }
    
    &__divider {
      display: none;
    }

    &__name {
      margin-bottom: 4px;
      color: #203551;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    &__code {
      margin-bottom: 4px;
      color: #14AD4D;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 18px;
      }
    }
    &__description {
      margin-bottom: 0px;
      color: #15171C;
      opacity: 0.4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      @media screen and (max-width: 500px) {
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .pp-button {
    &--fill {
      background: #3BBA6A;
      box-shadow: 0px 1px 2px 0px #0000001F;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 1 / 3;
      }
    }
    &--border {
      color: #14AD4D;
      background: #fff;
      border: 1px solid #14AD4D !important;

      @media screen and (max-width: 500px) {
        width: 100% !important;
        grid-column: 1 / 3;
      }
    }
  }
  &::v-deep {
    .vs-pagination {
      gap: 8px !important;

      &-content {
        gap: 8px !important;
      }

      &__button {
        color: #14AD4D;
        background-color: #FFF;
        border-radius: 40px;
        border: 1px solid #E0E9F4;
        border-radius: 40px !important;
      }

      &__active {
        color: #FFF;
        background-color: #14AD4D !important;
        box-shadow: 0px 10px 20px 0px #00136A40;
        border-radius: 40px !important;
      }

      &__arrow {
        background-color: #FFF;
        border: 1px solid #14AD4D !important;
        border-radius: 40px !important;
        
        .vs-icon-arrow::before,
        .vs-icon-arrow::after {
          background-color: #14AD4D !important;
        }

        &:disabled {
          opacity: 1 !important;
          border: 1px solid #E0E9F4 !important;
          
          .vs-icon-arrow::before,
          .vs-icon-arrow::after {
            background-color: #203551 !important;
          }
        }
      }
    }
  }
}

.pp-modal-vuesax {
  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 24px;

    h3 {
      font-size: 24px;
    }

    @media screen and (max-width: 500px) {
      padding: 16px 20px;

      h3 {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &__main {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: 454px;
    max-height: 454px;
    overflow-y: auto;

    @media screen and (max-width: 500px) {
      padding: 16px;
      gap: 16px;
    }

    .coupon__item {
      position: relative;
      flex-shrink: 0;
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 16px;
      padding: 9px 16px;
      letter-spacing: -0.2px;
      overflow: hidden;

      &__valid {
        margin-bottom: 0px;
        opacity: 0.4;
        color: #15171C;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .pp-button {
      &--fill {
        align-self: center;
        width: 114px;
        height: 32px !important;
        flex-shrink: 0;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        letter-spacing: -0.2px;
      }
      &--border {
        align-self: center;
        width: 114px;
        height: 32px !important;
        flex-shrink: 0;
        background: #FFF;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        letter-spacing: -0.2px;
        box-shadow: 0px 1px 2px 0px #0000001F;
      }
    }
  }

  .search-white {
    font-size: 14px;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px 15px 10px 35px;
    background-image: url(/img/search.svg);
    background-position: 12px 12px;
    background-repeat: no-repeat;
    border: 1px solid #e7edf4;
    border-color: #E0E9F4 !important;
    box-shadow: 0px 1px 2px 0px #1018280D;
  }

  &::v-deep {
    .vs-dialog {
      min-width: 0 !important;
      border-radius: 16px !important;
      overflow: hidden;

      &__content {
        margin-bottom: 0 !important;
      }

      &__close {
        top: 24px !important;
        right: 24px !important;
        background-color: #F6F8FC !important;
        padding: 2px !important;

        @media screen and (max-width: 500px) {
          top: 16px !important;
          right: 20px !important;
        }

        i {
          width: 24px;
          height: 24px;

          &::before,
          &::after {
            top: 13px;
            right: 7px;
            background: #BFCEE0 !important;
            height: 1.5px !important;
          }
        }
      }
    }
  }
}

button {
  height: 40px;
  color: #FFF;
  border: none;
  border-radius: 8px;
  border-color: #14AD4D !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.gap-16 {
  gap: 16px;
}

.color-mute {
  color: #6C7E95
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>