<template>
  <!-- RECIPIENT -->
  <div class="theme--tuktuk__checkout__requested-fields theme--tuktuk__card">
    <div class="theme--tuktuk__container">
      <h2 class="theme--tuktuk__title">{{ $t("tuktukTheme.contactInfo") }}</h2>
      <div class="theme--tuktuk__checkout__requested-fields__form">
        <div v-for="(row, index) in data.requestedFields" :key="index" class="theme--tuktuk__checkout__requested-fields__form__item text-left">
          <template v-if="row.active">
            <template
              v-if="showFields(index)"
            >
              <label v-if="data.isLabel == 'showLabel'" class="theme--tuktuk__checkout__requested-fields__form__label">
                {{ row.label }}
                {{
                  row.required == "yes"
                    ? ""
                    : data.formMode !== "Progressive Form"
                    ? "(Opsional)"
                    : ""
                }}
              </label>
              
              <div
                v-if="row.field == 'address' && row.key == 'ENTER_CITY_NAME'"
                :class="{
                  'is-invalid': data.errors[row.field],
                  'adjust-input-field': data.isLabel == 'showLabel',
                }"
                class="theme--tuktuk__checkout__requested-fields__form__address"
              >
                <AddressFill
                  ref="enterCity"
                  :className="{
                    'is-invalid': data.errors[row.field],
                    'vs-form-control': true,
                  }"
                  @onChange="changeAddress"
                  :placeholder="row.placeholder"
                  :dontIncludeSubdistrict="data.payload.dontIncludeSubdistrict"
                  :dontIncludeZipcode="data.payload.dontIncludeZipcode"
                  :fieldName="row.key"
                  :addressVal="data.payload.address"
                  :shippingData="{
                    enableExcludeShipping: data.enableExcludeShipping,
                    excludeShipping: data.excludeShipping,
                    excludeShippingLocation: data.excludeShippingLocation,
                  }"
                />

                <!-- <div style="margin-top: 10px" class="village-checkbox">
                  <vs-checkbox v-if="row.label == 'Kota / Kecamatan'" v-model="payload.dontIncludeSubdistrict">Tanpa
                    Kelurahan</vs-checkbox>
                </div> -->
              </div>

              <div
                v-else-if="row.field == 'address' && row.key != 'ENTER_CITY_NAME'"
                :class="{ 'is-invalid': data.errors[row.field] }"
                class="theme--tuktuk__checkout__requested-fields__form__address"
              >
              <SubAddress
                  :ref="row.key"
                  :className="{
                    'is-invalid': data.errors[row.field],
                    'vs-form-control': true,
                  }"
                  @onChange="changeAddress"
                  :placeholder="row.placeholder"
                  :dontIncludeSubdistrict="data.payload.dontIncludeSubdistrict"
                  :dontIncludeZipcode="data.payload.dontIncludeZipcode"
                  :fieldName="row.key"
                  :isLabel="data.isLabel"
                  :addressVal="data.payload.address"
                  :formMode="data.formMode"
                  :shippingData="{
                    enableExcludeShipping: data.enableExcludeShipping,
                    excludeShipping: data.excludeShipping,
                    excludeShippingLocation: data.excludeShippingLocation,
                  }"
                />
              </div>

              <template v-else-if="row.isCustom">
                <template v-if="row.fieldType == 'input'">
                  <vs-input
                    :ref="row.field"
                    v-if="row.inputType == 'number'"
                    class="vs-form-control"
                    v-model="row.value"
                    :class="{ 'is-invalid': data.errors[row.field] }"
                    type="number"
                    pattern="[0-9]*"
                    :placeholder="row.placeholder"
                  />

                  <date-picker
                    :ref="row.field"
                    v-else-if="row.inputType == 'date'"
                    :placeholder="row.placeholder"
                    :class="{ 'is-invalid': data.errors[row.field] }"
                    v-model="row.value"
                    type="date"
                  >
                  </date-picker>

                  <div v-else-if="row.inputType == 'file'" :ref="row.field" style="scroll-margin: 100px;">
                    <FileUploader
                      type="file"
                      :className="{
                        'is-invalid': data.errors[row.field],
                        'vs-form-control': true,
                      }"
                      v-model="row.value"
                    >
                    </FileUploader>
                  </div>

                  <vs-input
                    v-else
                    :ref="row.field"
                    class="vs-form-control"
                    v-model="row.value"
                    :class="{ 'is-invalid': data.errors[row.field] }"
                    type="text"
                    :placeholder="row.placeholder"
                  />
                </template>

                <div
                  v-else-if="row.fieldType == 'textarea'"
                  class="vs-form-control"
                >
                  <textarea
                    :ref="row.field"
                    :placeholder="row.placeholder"
                    :class="{ 'is-invalid': data.errors[row.field] }"
                    v-model="row.value"
                    id="subject"
                    rows="3"
                    cols="50"
                  ></textarea>
                </div>

                <div v-else :class="{ 'is-invalid': data.errors[row.field] }">
                  <vs-select
                    :ref="row.field"
                    :placeholder="row.placeholder"
                    class="vs-form-control"
                    :class="{ 'is-invalid': data.errors[row.field] }"
                    v-model="row.value"
                    style="scroll-margin: 100px;"
                  >
                    <vs-option
                      :key="val + j + 'f'"
                      v-for="(val, j) in row.options"
                      :label="val.name"
                      :value="val.name"
                      >{{ val.name }}</vs-option
                    >
                  </vs-select>
                </div>
              </template>

              <vs-input
                v-else-if="row.field == 'quantity'"
                ref="quantity"
                min="1"
                max="100"
                class="vs-form-control"
                v-model="row.value"
                :class="{ 'is-invalid': data.errors[row.field] }"
                type="number"
                :placeholder="row.placeholder"
                @change="chageQty"
              />

              <vs-input
                v-else-if="row.field == 'phone'"
                :ref="row.field"
                class="vs-form-control phone-input"
                v-model="row.value"
                :class="{ 'is-invalid': data.errors[row.field] }"
                :type="isMobile ? 'number' : 'text'"
                :placeholder="row.placeholder"
                pattern="[0-9]*"
                 v-on:blur="() => formatPhone()"
              />

              <template v-else-if="row.field == 'addressText'">
                <textarea
                  v-if="row.fieldType == 'textArea'"
                  :placeholder="row.placeholder"
                  :class="{ 'is-invalid': data.errors[row.field] }"
                  v-model="row.value"
                  class="addressText-textarea vs-form-control"
                  id="subject"
                  rows="5"
                ></textarea>
                <vs-input
                  v-else
                  class="vs-form-control"
                  v-model="row.value"
                  :class="{ 'is-invalid': data.errors[row.field] }"
                  type="text"
                  :placeholder="row.placeholder"
                />
              </template> 

              <vs-input
                v-else
                :ref="row.field"
                class="vs-form-control"
                v-model="row.value"
                :class="{ 'is-invalid': data.errors[row.field] }"
                type="text"
                :placeholder="row.placeholder"
              />

              <div v-if="data.errors[row.field] && row.field == 'address'" class="invalid-feedback mt-5px">
                {{ data.payload[row.field] && !data.payload[row.field][row.key.replace('_NAME', '')] ? data.errors[row.field] : '' }}
              </div>
              <div v-else-if="data.errors[row.field]" class="invalid-feedback mt-5px">
                {{ data.errors[row.field]  }}
              </div>
            </template>
          </template>
        </div>
         <!-- SAVE INFORMATION -->
         <template v-if="!data.saveInfoWithoutEnable">
          <div
            v-if="data.isSaveInfoNextTime"
            class="checkout-content--right__feature save-information"
          >
          <no-ssr>
            <vs-checkbox @click="saveDataForNexttime()">
              <div style="text-align: -webkit-left;">
                {{ data.saveInfoLabel ? data.saveInfoLabel : $t("common.saveInfoNextTime") }}
              </div>

              <!-- {{ $t("common.saveInfoNextTime") }} -->
            </vs-checkbox>
          </no-ssr>
          </div>
        </template>
        <div v-if="data.errors['zeroQTY']" class="invalid-feedback mt-0" style="text-align: left;">
          {{ data.errors['zeroQTY'] }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddressFill from "./../../../AddressFill.vue";
import SubAddress from "./../../../SubAddress.vue";
import FileUploader from "../../../FileUploader.vue";

export default {
  props: ["data", "isMobile"],
  data() {
    return {};
  },
  components: {
    AddressFill,
    SubAddress,
    FileUploader,
  },
  watch: {
    // 'data.cartQuantity': {
    //   async handler(val) {
    //     console.log(val,this.data.cartQuantity)
    //     if(this.data.type == 'simple'){
    //       const index=this.data.requestedFields.findIndex(x=>x.field=='quantity' && x.active);
    //       console.log("index",index,this.data.requestedFields)
    //       if(index > -1){
    //         this.data.requestedFields[index].value=val;
    //       }
    //   }
    //   },
    //   deep: true,
    // },
    'data.errors': {
      handler(errors) {
        document.documentElement.style.scrollBehavior = 'smooth'
        for (let row of (this.data.requestedFields || [])) {
          // handle address non enter city
          if (row.field === 'address' && row.active && row.key  !== 'ENTER_CITY_NAME' && errors[row.field]) {
            // TODO: should open select when error?
            this.$refs[row.key][0].$el.scrollIntoView({ behavior: 'smooth' })
            break;
          }
          // handle address with key "ENTER_CITY_NAME"
          if (row.field === 'address' && row.active && row.key === 'ENTER_CITY_NAME' && errors[row.field]) {
            // TODO: should open select when error?
            this.$refs.enterCity[0].$el.scrollIntoView({ behavior: 'smooth' })
            break;
          }
          // handle custom input textarea
          if (row.fieldType === 'textarea' && errors[row.field]) {
            this.$refs[row.field][0].scrollIntoView({ behavior: 'smooth' })
            break;
          }
          // handle custom input select
          if (row.fieldType === 'select' && errors[row.field]) {
            this.$refs[row.field][0].$el.scrollIntoView({ behavior: 'smooth' })
            break;
          }
          // handle custom input file
          const ref = this.$refs[row.field]
          if (ref && row.inputType === 'file' && errors[row.field]) {
            ref[0].scrollIntoView({ behavior: 'smooth' })
            break;
          }
          // handle common & custom input
          if (ref && errors[row.field]) {
            const input = ref[0].$el.querySelector('input');
            input.scrollIntoView({ behavior: 'smooth' });
            break;
          }
        }
        document.documentElement.removeAttribute('style')
      },
      deep: true,
    },
  },
  mounted() {
    // if (this.data.type == "simple") {
    //   const index = this.data.requestedFields.findIndex(
    //     (x) => x.field == "quantity" && x.active
    //   );     
    //   if (index > -1) {
    //     this.data.requestedFields[index].value = this.data.cartQuantity;
    //   }
    // }
  },
  methods: {
    formatPhone() {
      let phoneDataIndex = this.data.requestedFields.findIndex(
          (x) => x.field ==='phone'
        );
        if(phoneDataIndex && phoneDataIndex > -1){
          this.data.requestedFields[phoneDataIndex].value=this.data.requestedFields[phoneDataIndex].value.replaceAll('-', '').replaceAll('.', '')
        }
      
    },
    saveDataForNexttime() {
      this.$emit("saveDataForNexttime");     
    },
    chageQty() {
      if (this.data.type == "simple") {
        const index = this.data.requestedFields.findIndex(
          (x) => x.field == "quantity" && x.active
        );  
        if (index > -1) {
          this.data.cartQuantity = this.data.requestedFields[index].value;
          this.updateCartQuantity();
        }
      }
    },
    updateCartQuantity() {
      this.$emit("updateCartQuantity", this.data.cartQuantity);
    },
    findLastIndex(array, searchKey, searchValue, label) {
      let revArr = array.slice().reverse();
      let gValue = revArr.findIndex((x) => {
        return x.label === label;
      });
      var index = revArr.findIndex((x, i) => {
        return x[searchKey] === searchValue && i > gValue;
      });
      var count = array.length - 1;
      var finalIndex = index >= 0 ? count - index : index;
      return finalIndex;
    },
    showFields(index) {     
      if((this.data.nuxt2 && process.client) || !this.data.nuxt2){
      let checkLast =  this.data.requestedFields.findLastIndex((e) => e.active);
      checkLast = checkLast > -1 ? checkLast : 0;
      if (
        (this.data.requestedFields[checkLast].field != "address" &&
          (this.data.requestedFields[checkLast].value ||
            this.data.requestedFields[checkLast].value != "")) ||
        (this.data.requestedFields[checkLast].field == "address" &&
          ((this.data.requestedFields[checkLast].key == "ENTER_CITY_NAME" &&
            this.data.payload.address) ||
            (this.data.requestedFields[checkLast].key != "ENTER_CITY_NAME" &&
              this.data.payload.address &&
              this.data.requestedFields[checkLast] &&
              this.data.requestedFields[checkLast].key &&
              this.data.payload.address[
                this.data.requestedFields[checkLast].key.split("_")[0]
              ])))
      ) {
        this.enableSubmitButton = true;
      }
      if (this.data.formMode == "Progressive Form" && index != 0) {
        let initialindex = this.data.requestedFields.findIndex(
          (x) => x.label === this.data.initialFields
        );
        if (
          index <= initialindex &&
          this.data.requestedFields[initialindex].active === true
        ) {
          return true;
        }
        let currentIndex = index - 1;
        if (
          currentIndex > -1 &&
          !this.data.requestedFields[currentIndex].active
        ) {
          let lastIndex = this.findLastIndex(
            this.data.requestedFields,
            "active",
            true,
            this.data.requestedFields[index].label
          );
          currentIndex = lastIndex;
        }  

        if (
          this.data.requestedFields[currentIndex] &&
          ((this.data.requestedFields[currentIndex].field != "address" &&
            (!this.data.requestedFields[currentIndex].value ||
              this.data.requestedFields[currentIndex].value == "")) ||
            (this.data.requestedFields[currentIndex].field == "address" &&
              (!this.data.payload.address ||
                (this.data.payload.address &&
                  ((this.data.requestedFields[currentIndex].key &&
                    !this.data.payload.address[
                      this.data.requestedFields[currentIndex].key == 'ENTER_CITY_NAME' ? 'CITY' : this.data.requestedFields[currentIndex].key.split("_")[0]
                    ]) ||
                    !this.data.payload.address)))))
        ) {
          
          return false;
        }
      }
    }
   
      return true;

    },
    changeAddress(val) {
      this.data.payload.address = val;
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.theme--tuktuk__checkout__requested-fields__form {
  input,
  .vs-select__input {
    font-family: TikTokDisplayMedium !important;
    font-size: 14px;
  }
  .vs-form-control {
    input {
      scroll-margin: 100px;
    }
    &.is-invalid input {
      border-bottom-color: red !important;
    }
    &.phone-input input[type=number]::-webkit-inner-spin-button, 
    &.phone-input input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
    }
  }
  .mx-datepicker {
    &.is-invalid input {
      border-color: red !important;
    }
    input {
      font-size: 14px;
      border: 0 !important;
      border-bottom: 1px solid rgba(161, 162, 164, 0.2) !important;

      &::placeholder {
        color: #15171c;
        opacity: 0.4;
      }
    }
  }

  textarea {
    &.is-invalid {
      border-color: red !important;
    }
    &:focus {
      outline-color: rgba(161, 162, 164, 0.2) !important;
    }
  }
  
  .vs-select.activeOptions .vs-select__input {
    border-color: transparent !important;
    border: 0 !important;
    border-bottom: 1px solid rgba(161, 162, 164, 0.2) !important;
  }
}
</style>
<style scoped>
.addressText-textarea{
  font-family: TikTokDisplayMedium !important;
  color: #15171c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.24px;
}
.addressText-textarea::placeholder {
  font-family: TikTokDisplayMedium !important;
  color: #15171c !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  opacity:0.4 !important;
}
</style>
