<template>
  <div v-if="data.payload" id="theme--tuktuk__view-order" class="theme--tuktuk__card">
    <div class="theme--tuktuk__container">
      <div class="d-flex align-items-center justify-between">
        <h2 class="theme--tuktuk__title">{{ $t("common.orderDetails") }}</h2>
        <div role="button" class="select-options--tuktuk__button__right-side" v-if="data.type != 'simple' && (!this.data.multiplyVariations && !this.data.cartOrder && !this.data.addQuantity) || (this.data.multiplyVariations && this.data.cartOrder && !this.data.addQuantity) || (!this.data.multiplyVariations && this.data.cartOrder && !this.data.addQuantity) "  @click="openChooseVariantModal()">
          <span>
            {{ $t("tuktukTheme.change") }}
          </span>
          <img src="@/assets/icons/TuktukTheme/ic-nav-tuktuk--next--pink.svg" />
        </div>
      </div>
      <!-- START ORDER SUMMARY LIST -->
      <div class="theme--tuktuk__view-order__list">
        <!-- PRODUCT -->
        <div v-if="data.type == 'simple'" class="theme--tuktuk__view-order__list__group">
          <div class="theme--tuktuk__view-order__list__item">
          <div class="theme--tuktuk__view-order__list__item__variant">
            <p class="theme--tuktuk__view-order__list__item__qty">
              
              {{
                data.cartQuantity
              }}x
            </p>
            <p class="theme--tuktuk__view-order__list__item__name">
              {{ data.name }}
            </p>
          </div>
          
          <div v-if="data.type == 'simple'" class="theme--tuktuk__view-order__list__item__price">
            <template v-if="data.priceCompare">
              <div class="theme--tuktuk__view-order__list__item__price__sale_container">
                <p class="theme--tuktuk__view-order__list__item__price__sale">
                Rp{{ data.cartPrice | g_number_format }}
              </p>
              <p class="theme--tuktuk__view-order__list__item__price__regular">
                <template v-if="data.priceCompare && data.type == 'simple'">
                  Rp{{ (data.cartQuantity * data.price) | g_number_format }}
                </template>
                <template v-else-if="parseInt(data.priceWithoutWhosale) !== parseInt(data.cartPrice)">
                  Rp{{ data.priceWithoutWhosale | g_number_format }}
                </template>
              </p>
           </div>
            </template>
            <template v-else>
              <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp{{ data.cartPrice | g_number_format }}
            </p>      
           </template>
           
          </div>
          </div>
        </div>
     
        <div v-if="data.type != 'simple'" class="theme--tuktuk__view-order__list__group">
          <p class="theme--tuktuk__sub-title">
            {{ data.name }}
          </p>
          <template
            v-if="
              !data.multiplyVariations ||
              data.type == 'simple' ||
              (data.multiplyVariations && data.cartOrder)
            "
          >
            <template
              v-if="
                data.cartSummary &&
                data.cartSummary.length > 0 &&
                data.multiplyVariations
              "
            >
              <template v-for="(elem, i) in data.cartSummary">
                <div
                  v-if="elem.qty"
                  v-bind:key="i"
                  class="theme--tuktuk__view-order__list__item "
                >
                  <!-- PRODUCT QTY AND VARIANT NAME -->
                  <div class="theme--tuktuk__view-order__list__item__variant">
                    <p class="theme--tuktuk__view-order__list__item__qty">{{ elem.qty }}x</p>
                    <div class="theme--tuktuk__view-order__list__item__variant__list">
                      <p v-for="(elemChild, j) in Object.keys(elem.items)"
                          v-bind:key="j + 'p'"
                          class="theme--tuktuk__view-order__list__item__name" >
                          {{ elemChild.substring(0, elemChild.length - 1) }} {{ elem.items[elemChild].name }}
                      </p>
                    </div>
                  </div>

                  <!-- PRODUCT PRICE -->
                  <div class="theme--tuktuk__view-order__list__item__price">

                      <template
                      v-if="
                      getSubPrice(elem)  > 0 &&
                      getSubPrice(elem) !== elem.priceOriginal * elem.qty
                      "
                      >
                      <h2 class="theme--tuktuk__view-order__list__item__price__sale">
                      
                        Rp{{ getSubPrice(elem) | g_number_format }}
                      </h2>
                      <div
                        class="theme--tuktuk__view-order__list__item__price__sale_container"
                      >
                        <p class="theme--tuktuk__view-order__list__item__price__regular">
                          <template>
                            Rp{{(elem.priceOriginal * elem.qty) | g_number_format}}
                          </template>
                        </p>                  
                      </div>
                      </template>
                      <template v-else>
                      <h2 class="theme--tuktuk__view-order__list__item__price__regular">
                        Rp{{(elem.priceOriginal * elem.qty) | g_number_format}}
                      </h2>
                      </template>            


                      </div>
                  <!-- <div class="theme--tuktuk__view-order__list__item__price">
                    <p v-if="elem.price == 0" class="theme--tuktuk__view-order__list__item__price__regular">
                      Rp{{ (elem.priceOriginal * elem.qty) | g_number_format }}
                    </p>
                    <template v-if="elem.priceOriginal && elem.price > 0">
                      <p class="theme--tuktuk__view-order__list__item__price__regular">Rp{{ getSubPrice(elem) | g_number_format }}</p>
                      <p
                        v-if="getSubPrice(elem) !== elem.priceOriginal * elem.qty"
                        class="theme--tuktuk__view-order__list__item__price__sale"
                        style="text-decoration: line-through;"
                      >
                        Rp{{(elem.priceOriginal * elem.qty) | g_number_format}}
                      </p>
                    </template>
                </div> -->
                
                </div>
              </template>
            </template>

            <div v-else class="theme--tuktuk__view-order__list__item" >
              <!-- PRODUCT QTY AND VARIANT NAME -->
              <div class="theme--tuktuk__view-order__list__item__variant">
                <p class="theme--tuktuk__view-order__list__item__qty">{{ data.cartQuantity }}x</p>
                <div class="theme--tuktuk__view-order__list__item__variant__list">
                  <p
                    :key="l + 'p'"
                    v-for="(vprice, l) in Object.keys(
                      data.payload.variablePrices
                    )"
                    class="theme--tuktuk__view-order__list__item__name"
                  >
                    {{ vprice.substring(0, vprice.length - 1) }} {{ data.payload.variablePrices[vprice].name }}
                  </p>  
                </div>
                           
              </div>

              <div class="theme--tuktuk__view-order__list__item__price">

                <template
                v-if="
                  parseInt(data.priceWithoutWhosale) !==
                    parseInt(data.cartPrice)
                "
              >
                <h2 class="theme--tuktuk__view-order__list__item__price__sale">
                  Rp{{ data.cartPrice | g_number_format }}
                 
                </h2>
                <div
                  class="theme--tuktuk__view-order__list__item__price__sale_container"
                >
                  <p class="theme--tuktuk__view-order__list__item__price__regular">
                    <template>
                      Rp{{ data.priceWithoutWhosale | g_number_format }}
                    </template>
                  </p>                  
                </div>
              </template>
              <template v-else>
                <h2 class="theme--tuktuk__view-order__list__item__price__regular">
                  Rp{{ data.cartPrice | g_number_format }}
                </h2>
              </template>            
            

              </div>
            </div>
          </template>

          <!-- VARIANT ORDER MULTIPLY VARIATION -->
          <template v-else>
            <template v-for="(attr, i) in data.variablePrices">
              <div
                v-if="attr.qty > 0"
                :key="'f' + i"
                class="theme--tuktuk__view-order__list__item"
              > 
                <div class="theme--tuktuk__view-order__list__item__variant">
                  <p class="theme--tuktuk__view-order__list__item__qty">{{ attr.qty }}x</p>
                  <div class="theme--tuktuk__view-order__list__item__variant__list">
                    <p v-for="(elemChild, j) in attr.attrs"
                        v-bind:key="j + 'p'"
                        class="theme--tuktuk__view-order__list__item__name" >
                        {{ elemChild.name.substring(0, elemChild.name.length - 1) }} {{ elemChild.value }}
                    </p>
                  </div>
                </div>
                <div class="theme--tuktuk__view-order__list__item__price">

                        <template
                        v-if="
                          getSubPrice(attr) !== attr.price * attr.qty
                        "
                        >
                        <h2 class="theme--tuktuk__view-order__list__item__price__sale">
                          Rp{{ getSubPrice(attr) | g_number_format }}
                        
                        </h2>
                        <div
                          class="theme--tuktuk__view-order__list__item__price__sale_container"
                        >
                          <p class="theme--tuktuk__view-order__list__item__price__regular">
                            <template>
                             
                              Rp{{ (attr.price * attr.qty) | g_number_format }}
                            </template>
                          </p>                  
                        </div>
                        </template>
                        <template v-else>
                        <h2 class="theme--tuktuk__view-order__list__item__price__regular">
                          
                          Rp{{ (attr.price * attr.qty) | g_number_format }}
                        </h2>
                        </template>            


                        </div>
                <div class="theme--tuktuk__view-order__list__item__price">
                  <p class="theme--tuktuk__view-order__list__item__price__regular"></p>
                  <p
                    v-if="getSubPrice(attr) !== attr.price * attr.qty"
                    class="theme--tuktuk__view-order__list__item__price__sale"
                  >
                   
                  </p>
                </div>
              </div>
            </template>
          </template>
        </div>

        <!-- BUMP -->
        <div
          v-if="data.isBump && data.selectedbumpArray.length > 0"
          class="theme--tuktuk__view-order__list__group"
        >
          <p class="theme--tuktuk__sub-title">{{ $t("common.bumpProduct") }}</p>
          <div
            v-for="(data, i) in data.selectedbumpArray"
            :key="i"
            class="theme--tuktuk__view-order__list__item"
          >
            <p class="theme--tuktuk__view-order__list__item__name">{{ data.bumpName }}</p>
            <div class="theme--tuktuk__view-order__list__item__price">
              <p class="theme--tuktuk__view-order__list__item__price__regular">
                Rp{{ data.bumpPrice | g_number_format }}
              </p>
            </div>
          </div>
        </div>

        <div class="theme--tuktuk__view-order__list__group" v-if="(data.payload.discounts && data.payload.discounts.length) || (data.payload.automaticDiscount && data.payload.automaticDiscount.code)" >
          <!-- DISCOUNT -->
          <!-- <div class="theme--tuktuk__view-order__list__item">
            <p class="theme--tuktuk__view-order__list__item__name">Diskon dari penjual</p>
            <div class="theme--tuktuk__view-order__list__item__price">
              <p class="theme--tuktuk__view-order__list__item__price__sale">
                - Rp{{ 152000 | g_number_format }}
              </p>
            </div>
          </div> -->
          <div class="theme--tuktuk__view-order__list__item" v-for="(discount, index) in data.payload.discounts" :key="index">
            <p class="theme--tuktuk__view-order__list__item__name">
              {{discount.code}}       
            </p>
            <div class="theme--tuktuk__view-order__list__item__price">
              <p class="theme--tuktuk__view-order__list__item__price__sale">
                - Rp{{ discount.amount | g_number_format }}
              </p>
            </div>
          </div>
        <div class="theme--tuktuk__view-order__list__item" v-if="data.payload.automaticDiscount && data.payload.automaticDiscount.code">
          <p class="theme--tuktuk__view-order__list__item__name">          
            {{data.payload.automaticDiscount.code}}
          </p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__sale">
              - Rp{{ data.payload.automaticDiscount.amount | g_number_format }}
            </p>
          </div>
        </div>
        </div>

        <!-- SHIPPING COST AUTOMATES -->
        <div
          v-if="
            data.addShipping &&
            shippingType == 'automated' &&
            parseInt(data.payload.deliveryPrice) + parseInt(additionalCost) >
              0
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.shippingCosts") }} ({{ data.shippingWeight | gramsToKilograms_format}}kg)</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              <template v-if="data.payload.deliveryPrice">
                Rp{{ (parseInt(data.payload.deliveryPrice) + (hideAdditionalCost ? parseInt(additionalCost ? additionalCost : 0) : 0)) | g_number_format }}
              </template>
              <template v-else>
                {{ parseInt(0) | g_number_format }}
              </template>
            </p>
          </div>
          
        </div>
        <div
          v-if="
            shippingType == 'automated' &&
            data.addShipping &&
            shippingDiscount &&
            shippingDiscount.status &&
            parseInt(shippingDiscountValue) > 0
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("tuktukTheme.shippingDiscounts") }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__sale">-Rp{{ parseInt(shippingDiscountValue) | g_number_format }}</p>
          </div>
        </div>

        <!-- ADDTIONAL SHIPPING COST -->
        <div
          v-if="
            shippingType == 'automated' &&
            data.addShipping &&
            additionalCost &&
            parseInt(additionalCost) != 0 &&
            !hideAdditionalCost
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.additionalShippingCost") }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">Rp{{ parseInt(additionalCost) | g_number_format }}</p>
          </div>
        </div>

        <!-- SHIPPING COST FLAT -->
        <div
          v-if="
            data.addShipping &&
            shippingType == 'flat' &&
            parseInt(flatShippingPrice ? flatShippingPrice : 0) +
              parseInt(additionalCost) >
              0
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.shippingCosts") }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp{{
                parseInt(flatShippingPrice ? flatShippingPrice : 0)
                  | g_number_format
              }}
            </p>
          </div>
        </div>

        <!-- COD COST -->
        <div
          v-if="isPaymentCOD"
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.codFee") }}</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp{{ data.codPrice | g_number_format }}
            </p>
          </div>
        </div>

        <!-- EPAY FEE -->
        <div
          v-if="
            this.data.payload.payment &&
            this.data.payload.payment != 'bankTransfer' &&
            this.data.payload.payment != 'COD' &&
            this.data.ePaymentFeePaidBy === 'buyer'
          "
          class="theme--tuktuk__view-order__list__item"
        >
          <div>
            <p class="theme--tuktuk__view-order__list__item__name">{{ paymentFeeMethodName(this.data.payload.payment) }}</p>
            <p class="admin-fee m-0" v-if="this.data.payload.payment === 'gopay'">{{ $t('settings.adminEPayFeeGopay') }}</p>
            <p class="admin-fee m-0" v-else-if="this.data.payload.payment === 'QRIS'">{{ $t('settings.adminEPayFeeQRIS') }}</p>
            <p class="admin-fee m-0" v-else-if="this.data.payload.payment === 'credit_card'">{{ $t('settings.adminEPayFeeCreditCard') }}</p>
            <p class="admin-fee m-0" v-else>{{ $t('settings.adminEPayFeeOther') }}</p>
          </div>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp{{ calEpayFee() | g_number_format }}
            </p>
          </div>
        </div>
      
        <!-- UNIQUE PRICE -->
         <no-ssr>
          <div
            v-if="
              data.payload.uniquePrice && data.payload.payment == 'bankTransfer'
            "
            class="theme--tuktuk__view-order__list__item"
          >
            <p class="theme--tuktuk__view-order__list__item__name">{{ $t("common.uniquePrice") }}</p>
            <div class="theme--tuktuk__view-order__list__item__price">
              <p class="theme--tuktuk__view-order__list__item__price__regular">
                Rp {{ this.data.payload.uniqueType == "Decrease" ? "-" : "" }} {{ data.payload.uniquePrice }}
              </p>
            </div>
          </div>
        </no-ssr>
         <!-- VAT (PPN) -->
        <div
          v-if="data.VAT.isVAT && data.VAT.VATpercentage > 0"
          class="theme--tuktuk__view-order__list__item"
        >
          <p class="theme--tuktuk__view-order__list__item__name">PPN {{ data.VAT.VATpercentage }}%</p>
          <div class="theme--tuktuk__view-order__list__item__price">
            <p class="theme--tuktuk__view-order__list__item__price__regular">
              Rp{{ (data.cartSummary.length > 0 && cartTotalPrice ? Math.round((cartTotalPrice * data.VAT.VATpercentage)/100) : Math.round(data.VATPrice)) | g_number_format }}
            </p>
          </div>
        </div>
      </div>
      <!-- END ORDER SUMMARY LIST -->

      <!-- TOTAL -->
      <div class="theme--tuktuk__view-order__total">
        <p class="theme--tuktuk__title">{{ $t("common.total") }}</p>
        <p class="theme--tuktuk__title  theme--tuktuk__view-order__total__number">
          <template v-if="data.multiplyVariations">
            <template
              v-if="
                this.data.payload.payment &&
                this.data.payload.payment != 'bankTransfer' &&
                this.data.payload.payment != 'COD'
              "
            >
              Rp{{ data.totalPrice | g_number_format }}
            </template>
            <template v-else>
                Rp{{
                (data.cartSummary.length > 0 && cartTotalPrice
                ? Math.round(cartTotalPrice + (data.VAT && data.VAT.isVAT ? (cartTotalPrice * data.VAT.VATpercentage)/100 : 0))
                : data.totalPrice) | g_number_format    
              }}
            </template>
          </template>
          <template v-else>
            <template
              v-if="
                this.data.payload.payment &&
                this.data.payload.payment != 'bankTransfer' &&
                this.data.payload.payment != 'COD'
              "
            >
              Rp{{ data.totalPrice | g_number_format }}
            </template>
            <template v-else>Rp{{ data.totalPrice | g_number_format }}</template>
          </template>
        </p>
      </div>
      <template v-if="data.themeType=='Tuktuk'">
        <no-ssr>
        <vs-dialog v-if="!data.embed" overflow-hidden class="theme--tuktuk__modal tuktuk-modal-variant" :class="(!data.multiplyVariations ||
          (data.multiplyVariations)) && data.showProductImages && (data.variantImageIndex > -1 || data.productImages.length > 0)
          ? 'tuktuk-modal-variant--show-thumbnail'
          : ''
          " v-model="chartModal">
          <SelectProduct @updateCartQuantity="updateCartQuantity" :data="{ ...data }" :payload="data.payload"  :itemIdex="selectedVariantIndex"
                @addToCart="addToCart" @showMsg="showMsg" :isVariantModalOpen="isVariantModalOpen" @setStep="setStep" @changeVariant="changeVariant" @increment="incrementSelect" @decrement="decrementSelect" @addCartItem="addCartItem" />
        </vs-dialog>

        <div v-else class="theme--tuktuk__modal select-options--tuktuk-modal tuktuk-modal-variant"
          :class="{
            'theme--tuktuk__modal--show': chartModalEmbed, 'select-options--tuktuk-modal--show-thumbnail': (!data.multiplyVariations ||
              (data.multiplyVariations)) && data.showProductImages && (data.variantImageIndex > -1 || data.productImages.length > 0)
          }">
          <div class="theme--tuktuk__modal__overflow" @click="chartModalEmbed = false"></div>
          <SelectProduct ref="SelectProduct" @updateCartQuantity="updateCartQuantity" :data="{ ...data }" :payload="data.payload" :itemIdex="selectedVariantIndex"
                @addToCart="addToCart" @showMsg="showMsg" :isVariantModalOpen="isVariantModalOpen" @setStep="setStep" @changeVariant="changeVariant" @increment="incrementSelect" @decrement="decrementSelect" @addCartItem="addCartItem"/>
        </div>
      </no-ssr>
    </template>
    </div>
  </div>
</template>
<script>
import { paymentFeeMethodName, calculateEpayFee } from "@/helpers/epayMethodHelper";
import SelectProduct from "@/components/Form/Tuktuk/ProductDetails/SelectProduct";
export default {
  props: ["data","isVariantModalOpen"],
  data() {
    return {
      paymentFeeMethodName,
      chartModal:false,
      chartModalEmbed:false,
      selectedVariantIndex: 0,  
      calculateEpayFee,
    };
  },
  components: {
    SelectProduct
  },
  mounted(){
    this.$emit("calcCart");
  },
  computed:{
    cartTotalPrice: {
      get() {
        return this.data.cartTotal;
      },
      set(val) {
        this.$emit("updateCartTotal", val);
      },
    },

    isPaymentCOD: {
      get() {
        return this.data.payload.payment === 'COD';
      }
    },

    shippingType: {
      get() {
        if (this.isPaymentCOD) {
           return this.data.shippingCOD.shippingType;
        }
        return this.data.shippingType;
      }
    },

    shippingDiscount: {
      get() {
        if (this.isPaymentCOD) {
           return this.data.shippingCOD.shippingDiscount;
        }
        return this.data.shippingDiscount;
      }
    },

    shippingDiscountValue: {
      get() {
        if (this.shippingType === 'automated' && this.shippingDiscount.status) {
          const shippingDiscountValueDb = this.shippingDiscount.value;
          if (this.shippingDiscount.type === 'percentage'){
            return (this.data.payload.deliveryPrice * shippingDiscountValueDb) / 100;
          }
          return shippingDiscountValueDb;
        }

        return 0;
      }
    },

    hideAdditionalCost: {
      get() {
        if (this.isPaymentCOD) {
           return this.data.shippingCOD.hideAdditionalCost || false;
        }
        return this.data.hideAdditionalCost || false;
      }
    },

    additionalCost: {
      get() {
        if (this.isPaymentCOD) {
           return this.data.shippingCOD.additionalCost || 0;
        }
        return this.data.additionalCost || 0;
      }
    },

    flatShippingPrice: {
      get() {
        if (this.isPaymentCOD) {
           return this.data.shippingCOD.flatShippingPrice || 0;
        }
        return this.data.flatShippingPrice || 0;
      }
    },
    dataVariablePrices() {
      if (this.data.hideUnavailableProductVariant) {
        return this.data.variablePrices.filter(variablePrice => !variablePrice.delete);
      }

      return this.data.variablePrices;
    },
  },
  methods: {   
    addCartItem() {
      this.$emit("addCartItem");
    },
    incrementSelect(val, type) {
      if (type == "multipleVariant" && !this.data.cartOrder) {
        if (this.dataVariablePrices[val]) {
          this.dataVariablePrices[val].qty =
            parseInt(this.dataVariablePrices[val].qty) + 1;
        }
        if(this.dataVariablePrices[val].qty==1){
          this.addCartItem();
        }
        
      } else if (type == "singleVariant") {
        this.data.cartQuantity = this.data.cartQuantity + 1;
        this.updateCartQuantity();
      }
      this.$forceUpdate();
      if(this.data.cartOrder &&  this.data.multiplyVariations &&  !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart){
          setTimeout(() => {
            this.addToCart('update')
          },500);   
        }
    },
    decrementSelect(val, type) {
      if (type == "multipleVariant" && !this.data.cartOrder) {
        if (
          this.dataVariablePrices[val].qty &&
          this.dataVariablePrices[val].qty > 0
        ) {
          if (this.dataVariablePrices[val].qty < 1) {
            return;
          } else {
            this.dataVariablePrices[val].qty =
              this.dataVariablePrices[val].qty - 1;
          }
        }
        
      } else if (type == "singleVariant") {
        if (this.data.cartQuantity < 2) {
          if(this.data.cartSummary.length>1){
              if (this.data.cartQuantity < 1) {
                return;
              } else {       
                
                let selectedVariant = this.getSelectedVariantName(this.data.payload.variablePrices)     
                if(this.data.cartSummary.length > 0 && this.data.multiplyVariations && this.data.cartOrder){
                  let priceVarIndex = this.data.cartSummary.findIndex(
                    (e) => e.name == selectedVariant
                  );
                  this.data.cartQuantity=0;
                  this.updateCartQuantity()
                  this.removeFromCart(priceVarIndex);
                }  
                
              }
            }
          return;        
        } else {
          this.data.cartQuantity = this.data.cartQuantity - 1;
        }
        this.updateCartQuantity();
      }
      if(this.data.cartOrder &&  this.data.multiplyVariations &&  !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart){
          setTimeout(() => {
            this.addToCart('update')
          },500);   
        }
      this.$forceUpdate();
    },
    increment(val, type) {
      if (
        type == "multipleVariant" &&
        this.data.cartOrder &&
        this.data.addQuantity
      ) {
        if (this.data.cartSummary[val]) {
          this.data.cartSummary[val].qty =
            parseInt(this.data.cartSummary[val].qty) + 1;
        }
        this.updateCartQuantity();
      } else if (type == "multipleVariant" && !this.data.cartOrder) {
        if (this.data.variablePrices[val]) {
          this.data.variablePrices[val].qty =
            parseInt(this.data.variablePrices[val].qty) + 1;            
        }
        this.updateCartQuantity();
      } else if (type == "singleVariant") {
        this.data.cartQuantity = parseInt(this.data.cartQuantity) + 1;
        this.updateCartQuantity();
      }
      this.$forceUpdate();
    },
    decrement(val, type) {
      if (
        type == "multipleVariant" &&
        this.data.cartOrder &&
        this.data.addQuantity
      ) {
        if (
          this.data.cartSummary[val] &&
          this.data.cartSummary[val].qty &&
          this.data.cartSummary[val].qty > 0
        ) {
          if (this.data.cartSummary[val].qty < 2) {
            return;
          } else {
            this.data.cartSummary[val].qty = this.data.cartSummary[val].qty - 1;
          }
          this.updateCartQuantity();
        }
      } else if (type == "multipleVariant" && !this.data.cartOrder) {
        if (
          this.data.variablePrices[val].qty &&
          this.data.variablePrices[val].qty > 0
        ) {
          if (this.data.variablePrices[val].qty < 2) {
            return;
          } else {
            this.data.variablePrices[val].qty =
              this.data.variablePrices[val].qty - 1;
          }
        }
      } else if (type == "singleVariant") {
        if (this.data.cartQuantity < 2) {
          return;
        } else {
          this.data.cartQuantity = parseInt(this.data.cartQuantity) - 1;
        }
        this.updateCartQuantity();
      }

      this.$forceUpdate();
    },
    setStep() {
      this.chartModal=false;
      this.chartModalEmbed=false;
      this.$emit("changeVariant");
     // this.$emit("setStep");
    },
    changeVariant() {
      setTimeout(()=>{
        if(this.data.embed && this.data.themeType=='TuktukStep1'){        
          this.$refs['SelectProduct'].$el.scrollIntoView({ behavior: 'smooth' }) 
        }  
      },200) 
      this.$emit("changeVariant");
    },
    updateCartQuantity() {
      this.$emit("updateCartQuantity", this.data.cartQuantity);
    },
    addToCart() {
      this.$emit("addToCart");
    },
    showMsg() {
      this.$emit("showMsg");
    },
    getSelectedVariantName(param) {
      let selectedVariant = Object.values(param);
      selectedVariant = selectedVariant.map((data) => {
        return data.name;
      });
      return selectedVariant
        .toString()
        .replace(/[,]/g, " / ");
    },
    openChooseVariantModal(item) {       
      this.changeVariant = true;      
      this.chartModalEmbed = true;      
      if ((!this.data.multiplyVariations || (this.data.multiplyVariations && this.data.cartOrder)) && item) {
        item.attrs.map((attr) => {
          this.data.payload.variablePrices[
            attr.name
          ].name = attr.value

        })
      }
      let selectedVariant = this.getSelectedVariantName(this.data.payload.variablePrices)
     
      if(this.data.cartSummary.length > 0 && this.data.multiplyVariations && this.data.cartOrder){
        let priceVar = this.data.cartSummary.find(
          (e) => e.name == selectedVariant
        );
        this.data.cartQuantity=priceVar && priceVar.qty ? priceVar.qty: !this.data.tuktukThemeSetting.TuktukSettingsAddTOCart ? 0 : 1;
        this.$emit("updateCartQuantity", this.data.cartQuantity);
      }   
     
      this.$emit("changeVariant", {status:true,elem:item});
    },
    getSubPrice(attr) {
      let price = 0;
      if (attr.qty) {
        let subPrice = attr.priceCompare ? attr.priceCompare : attr.price;
        if (attr.whosaleData) {
          attr.whosaleData.map((one) => {
            if (one.count && parseInt(one.count) <= parseInt(attr.qty)) {
              subPrice = one.price;
            }
          });
        }
        price = price + subPrice * attr.qty;
      }
      return price;
    },
    calEpayFee() {
      return calculateEpayFee(this.data.payload.payment, this.data.ePaymentFeePaidBy || 'buyer', this.data.totalPrice);
    },
  },
  filters: {
   gramsToKilograms_format(val) {
      return (val / 1000).toFixed(1);
    },
  },
  watch:{
    "chartModal": {
      handler(val) {        
        this.$emit("changeVariant", val);
      },
    },
    "isVariantModalOpen":{
      handler(val) {
        this.changeVariant = val;
        this.chartModalEmbed = val;      
      }
    },
    "chartModalEmbed": {
      handler(val) {        
        this.$emit("changeVariant", val);
      }
    }, 
    "data.changeVariant": {
      handler(val) {        
        this.chartModal=val;
        this.chartModalEmbed=val;
        if(this.data.changeVariantItem && this.data.changeVariantItem.items){         
          Object.keys(this.data.changeVariantItem.items).map(key=>{
            this.data.payload.variablePrices[
            key
              ].name = this.data.changeVariantItem.items[key].name;

          });     
        }    
        if (this.data.changeVariantItem && this.data.changeVariantItem.attrs){
        
          this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === this.data.changeVariantItem.name);               
        }else if(this.data.changeVariantItem && this.data.changeVariantItem.items){
          
          this.selectedVariantIndex = this.data.variablePrices.findIndex(item => item.name === this.data.changeVariantItem.items);          
        }
      }
    }, 
  }
};
</script>

<style lang="scss" scoped>
.select-options--tuktuk__button__right-side {
  color: #EB435B;
  cursor: pointer;
}
.admin-fee {
  opacity: 0.4;
  font-size: 14px;
  font-weight: 400;
  color: #15171C;
}
</style>